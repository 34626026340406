import {
  CppPromotionInfo,
  Offer,
  OfferRenderType,
  OriginatingProduct,
  ReferralOfferDataList,
  RewardSplit,
  RewardType,
} from '../../lib/types';
import {
  determineRewardSplitType,
  getCppOfferData,
  getReferralOfferData,
} from '../../app/utils/commonFunctions';
import { copy, defaultCopy, canadaCopy, DefaultCopy } from './copy';

const getCopy = (
  product: OriginatingProduct,
  offerType: OfferRenderType,
  rewardSplit: RewardSplit,
  showCanadaCopy: boolean
) => {
  const overrideCopy = copy[product][offerType][rewardSplit];
  const canadaOverride = showCanadaCopy
    ? canadaCopy[product][offerType][rewardSplit]
    : undefined;

  const finalCopy: DefaultCopy = {
    ...defaultCopy,
    ...overrideCopy,
    ...canadaOverride,
  };
  return finalCopy;
};

export const generateCopy = (
  {
    cppPromotionInfo,
    referralOfferDataList,
    offerType,
    offerWindowDays,
    originatingProduct,
  }: Offer,
  referrerUsername?: string,
  referrerFirstName?: string,
  showCanadaCopy = false
) => {
  const sbkCppOfferData = getCppOfferData(
    cppPromotionInfo,
    'SB'
  ) as CppPromotionInfo;
  const casCppOfferData = getCppOfferData(
    cppPromotionInfo,
    'CASINO'
  ) as CppPromotionInfo;

  const sbkReferalOfferData = getReferralOfferData(
    referralOfferDataList,
    'SB'
  ) as ReferralOfferDataList;

  const casReferalOfferData = getReferralOfferData(
    referralOfferDataList,
    'CASINO'
  ) as ReferralOfferDataList;

  const sbkReferrerRewardAmount =
    sbkReferalOfferData?.referrerPromoRewardAmount ?? 0;
  const sbkRefereeRewardAmount = sbkCppOfferData?.refereeRewardAmount ?? 0;
  const sbkWagerAmount = sbkCppOfferData?.wagerAmount;

  const casinoReferrerRewardAmount =
    casReferalOfferData?.referrerPromoRewardAmount ?? 0;
  const casinoRefereeRewardAmount = casCppOfferData?.refereeRewardAmount ?? 0;
  const casinoWagerAmount = casCppOfferData?.wagerAmount;

  const productSpecificReferralOffer = referralOfferDataList?.find(
    (offer) => offer.product === originatingProduct
  );

  const productSpecificPromotionInfo = cppPromotionInfo?.find(
    (offer) => offer.product === originatingProduct
  );

  const rewardType =
    productSpecificReferralOffer?.referralOfferRewardType as RewardType;
  const { refereePercentage = 0, referrerPercentage = 0 } =
    productSpecificPromotionInfo || {};

  const rewardSplit = determineRewardSplitType(
    sbkReferrerRewardAmount,
    sbkRefereeRewardAmount,
    casinoReferrerRewardAmount,
    casinoRefereeRewardAmount,
    rewardType
  );

  const offerRenderType: OfferRenderType =
    offerType == 'HYBRID' ? 'hybrid' : 'standAlone';

  const rawCopy = getCopy(
    originatingProduct,
    offerRenderType,
    rewardSplit,
    showCanadaCopy
  );

  const name = referrerFirstName || referrerUsername;

  const replaceWith: {
    [id: string]: any;
  } = {
    '%sbkWagerAmount%': sbkWagerAmount,
    '%sbkReferrerRewardAmount%': sbkReferrerRewardAmount,
    '%sbkRefereeRewardAmount%': sbkRefereeRewardAmount,
    '%casinoWagerAmount%': casinoWagerAmount,
    '%casinoReferrerRewardAmount%': casinoReferrerRewardAmount,
    '%casinoRefereeRewardAmount%': casinoRefereeRewardAmount,
    '%offerWindowDays%': offerWindowDays || 30,
    '%referrerRewardAmount%':
      sbkReferrerRewardAmount + casinoReferrerRewardAmount,
    '%refereeRewardAmount%': sbkRefereeRewardAmount + casinoRefereeRewardAmount,
    '%referrerUsername%': name,
    '%refereePercentage%': refereePercentage,
    '%referrerPercentage%': referrerPercentage,
  };

  const processedCopy = JSON.parse(
    JSON.stringify(rawCopy).replace(
      /%sbkWagerAmount%|%sbkReferrerRewardAmount%|%sbkRefereeRewardAmount%|%casinoWagerAmount%|%casinoReferrerRewardAmount%|%casinoRefereeRewardAmount%|%offerWindowDays%|%referrerRewardAmount%|%refereeRewardAmount%|%referrerUsername%|%refereePercentage%|%referrerPercentage%/gi,
      (match) => replaceWith[match]?.toString()
    )
  );

  return processedCopy;
};
