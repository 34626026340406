import {
  CppPromotionInfo,
  OriginatingProduct,
  ReferralOfferDataList,
  RewardSplit,
} from '../../lib/types';

export const determineAsymmetric = (
  sbkReferrerRewardAmount: number,
  sbkRefereeRewardAmount: number,
  casinoReferrerRewardAmount: number,
  casinoRefereeRewardAmount: number,
  offerType: string
) => {
  if (offerType === 'SPORTSBOOK')
    return sbkReferrerRewardAmount !== sbkRefereeRewardAmount;
  if (offerType === 'CASINO')
    return casinoReferrerRewardAmount !== casinoRefereeRewardAmount;
  return false;
};

export const determineRewardSplitType = (
  sbkReferrerRewardAmount: number,
  sbkRefereeRewardAmount: number,
  casinoReferrerRewardAmount: number,
  casinoRefereeRewardAmount: number,
  rewardType: string
): RewardSplit => {
  if (rewardType === 'PRICE_BOOST_REWARD') return 'profitBoost';
  if (rewardType === 'BONUS_SPINS_REWARD') return 'bonusSpins';
  if (casinoRefereeRewardAmount === 0 && sbkRefereeRewardAmount === 0)
    return 'zeroRecruit';
  if (
    casinoReferrerRewardAmount === casinoRefereeRewardAmount &&
    sbkReferrerRewardAmount === sbkRefereeRewardAmount
  ) {
    return 'symmetric';
  }
  return 'asymmetric';
};

export const getCppOfferData = (
  cppPromotionInfo: CppPromotionInfo[],
  product: OriginatingProduct
) => cppPromotionInfo?.find((offer) => offer?.product === product);

export const getReferralOfferData = (
  referralOfferDataList: ReferralOfferDataList[],
  product: OriginatingProduct
) => referralOfferDataList?.find((offer) => offer?.product === product);
