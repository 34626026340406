import { useState, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import styled, { DefaultTheme } from 'styled-components';
import { Up, Down } from '../../assets/componentIcons';
import { ReferredUser } from '../../types/ReferralModel';
import { NoReferrals } from '../NoReferrals';
import { ReferralList } from '../ReferralList';

const Container = styled.div`
  border-radius: 4px;
  margin-top: 16px;
  ${({ theme }) => `
        background-color: ${theme.colors.contentBg};
        border: solid 1px ${theme.colors.containerBorder};
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                border-radius: ${isBrowser ? '4px' : '0px'};
                margin: ${isBrowser ? '16px' : '0px'};
            }`
        }
    `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 16px;
  &:hover {
    cursor: pointer;
  }
`;

const Left = styled.div`
  flex-direction: column;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.headerText};
  font-weight: Bold;
  font-size: 16px;
`;

const Message = styled.div`
  font-weight: Normal;
  font-size: 14px;
  ${({ theme }) => `
        color: ${theme.colors.headerText};
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                  font-size: 12px;
            }`
        }
    `}
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 50px;
`;

const Count = styled.div`
  color: ${({ theme }) => theme.colors.linkText};
  font-weight: Normal;
  font-size: 16px;
  padding-top: 2px;
`;

const Arrow = styled.div`
  color: ${({ theme }) => theme.colors.linkText};
  font-weight: Normal;
  font-size: 16px;
`;

const Content = styled('div')<{ isOpen: boolean }>`
  border-top: ${(props: { isOpen: boolean; theme: DefaultTheme }) =>
    props.isOpen ? `solid 1px ${props.theme.colors.containerBorder}` : '0px'};
  height: ${(props: { isOpen: boolean }) => !props.isOpen && '0px'};
  overflow: hidden;
  transition: height ease 0.2s;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

type YourReferralsProps = {
  referredUserList: ReferredUser[];
};

export const YourReferrals = ({ referredUserList }: YourReferralsProps) => {
  const [open, setOpen] = useState(true);

  const referredUserCount = referredUserList.length;

  useEffect(() => {
    setOpen(referredUserCount !== 0);
  }, []);

  const toggle = () => setOpen(!open);

  return (
    <Container data-testid="your-referrals">
      <Header onClick={toggle} data-testid="referral-header">
        <Left>
          <Title>Your Referrals</Title>
          <Message>See progress of friends you’ve referred</Message>
        </Left>
        <Right>
          <Count data-testid="referral-count">{referredUserCount}</Count>
          <Arrow>{open ? <Up /> : <Down />}</Arrow>
        </Right>
      </Header>
      <Content data-testid="referral-list" isOpen={open}>
        {referredUserCount ? (
          <ReferralList referredUserList={referredUserList} />
        ) : (
          <NoReferrals />
        )}
      </Content>
    </Container>
  );
};
