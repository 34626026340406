import styled from 'styled-components';
import { useCopy } from '../../contexts/CopyContext';
import React from 'react';

const StyledText = styled.span`
  ${({ theme }) => `
        color: ${theme.colors.topSectionText};
        font-size: ${theme.welcome.text.size};
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                font-size: ${theme.welcome.text.extraSmallSize};
            }`
        }
    `}
  white-space: pre-wrap;
`;

const BoldStyledText = styled(StyledText)`
  font-weight: bold;
  ${({ theme }) => `
        color: ${theme.colors.topSectionText};
    `}
  white-space: pre-wrap;
`;

type WelcomeTextProps = {
  isBonusSpinsZeroRecruit?: boolean;
};

export const WelcomeText: React.FC<WelcomeTextProps> = ({
  isBonusSpinsZeroRecruit,
}) => {
  const copy = useCopy();
  const { welcomeText, welcomeTextReward } = isBonusSpinsZeroRecruit
    ? copy.zeroRecruit ?? {}
    : copy;

  return (
    <>
      <StyledText>{welcomeText}</StyledText>
      <BoldStyledText>{welcomeTextReward}</BoldStyledText>
    </>
  );
};
