import betSlip from '../../assets/icons/common/BetSlip.svg';
import cards from '../../assets/icons/common/CardsCircle.svg';
import dollarCircle from '../../assets/icons/common/DollarCircle.svg';
import casBonusSpins from '../../assets/icons/common/CasBonusSpins.svg';
import boostIcon from '../../assets/icons/common/Boost.svg';
import player from '../../assets/icons/common/Player.svg';
import { ProductType } from '../../types/ReferralModel';

export interface HowItWorksStep {
  label: string;
  labelExtension: string;
  description: string;
  icon: string;
  isBonusColor: boolean;
  product?: ProductType;
  terms?:string;
}

export const joinStep: HowItWorksStep = {
  label: 'Your friend joins',
  labelExtension: '',
  description: 'Using your unique invite link',
  icon: player,
  isBonusColor: false,
};

export const sbkWagerStep: HowItWorksStep = {
  label: `They wager $%sbkWagerAmount%+`,
  labelExtension: ' on Sportsbook',
  description:
    'Bet must be placed and settled within %offerWindowDays% days of joining',
  icon: betSlip,
  isBonusColor: false,
  product: 'SPORTSBOOK',
};

export const sbkBonusStep: HowItWorksStep = {
  label: `You each get $%sbkReferrerRewardAmount% in Bonus Bets`,
  labelExtension: '',
  description: 'To play on FanDuel Sportsbook!',
  icon: dollarCircle,
  isBonusColor: true,
  product: 'SPORTSBOOK',
};

export const sbkBonusAsymmetricStep: HowItWorksStep = {
  label: `You get $%sbkReferrerRewardAmount% in Bonus Bets`,
  labelExtension: '',
  description:
    'And your friend gets $%sbkRefereeRewardAmount% in Bonus Bets to keep playing',
  icon: dollarCircle,
  isBonusColor: true,
  product: 'SPORTSBOOK',
};
export const sbkProfitBoostToken: HowItWorksStep = {
  label: `You each get (%sbkRefereeRewardAmount%) %percentage%% Profit Boost Tokens`,
  labelExtension: '',
  description: 'To play on FanDuel Sportsbook!',
  icon: boostIcon,
  isBonusColor: true,
  product: 'SPORTSBOOK',
};

export const casWagerStep: HowItWorksStep = {
  label: `They play through $%casinoWagerAmount%+ on Casino`,
  labelExtension: '',
  description:
    'Play through must happen within %offerWindowDays% days of joining',
  icon: cards,
  isBonusColor: false,
  product: 'CASINO',
};

export const casBonusStep: HowItWorksStep = {
  label: `You each get $%casinoReferrerRewardAmount% in Bonus`,
  labelExtension: '',
  description: 'To play on FanDuel Casino!',
  icon: dollarCircle,
  isBonusColor: true,
  product: 'CASINO',
};

export const casBonusAsymmetricStep: HowItWorksStep = {
  label: `You get $%casinoReferrerRewardAmount% in Bonus`,
  labelExtension: '',
  description:
    'And your friend gets $%casinoRefereeRewardAmount% to play on FanDuel Casino!',
  icon: dollarCircle,
  isBonusColor: true,
  product: 'CASINO',
};

export const sbkBonusStepZeroRecruit: HowItWorksStep = {
  label: `You get $%sbkReferrerRewardAmount% in Bonus Bets`,
  labelExtension: '',
  description: 'To play on FanDuel Sportsbook!',
  icon: dollarCircle,
  isBonusColor: true,
  product: 'SPORTSBOOK',
};

export const casBonusStepZeroRecruit: HowItWorksStep = {
  label: `You get $%casinoReferrerRewardAmount% in Bonus`,
  labelExtension: '',
  description: 'To play on FanDuel Casino!',
  icon: dollarCircle,
  isBonusColor: true,
  product: 'CASINO',
};

export const casBonusSpinsStep: HowItWorksStep = {
    label: `You both get %casinoReferrerRewardAmount% Bonus Spins`,
    labelExtension: '',
    description: `To play in slot games on FanDuel Casino!`,
    terms:'See terms and conditions',
    icon: casBonusSpins,
    isBonusColor: true,
    product: 'CASINO',
};

export const casBonusSpinsZeroRecruitStep: HowItWorksStep = {
  label: `You get %casinoReferrerRewardAmount% Bonus Spins`,
  labelExtension: '',
  description: `To play in slot games on FanDuel Casino!`,
  terms:'See terms and conditions',
  icon: casBonusSpins,
  isBonusColor: true,
  product: 'CASINO',
};
