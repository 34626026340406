import { ReactSVG } from 'react-svg';
import { useTheme } from 'styled-components';

import { Country, getCppOfferData } from '../../../app/utils';
import { CppPromotionInfo, OfferDetails, RewardType } from 'Types';
import { CopyContext } from 'Contexts';
import { LandingHowItWorks, Header, Footer, LandingWelcome } from 'Components';

import {
  Container,
  Graphic,
  LandingStyle,
  SvgWrapper,
  TopMargin,
} from './LandingPageStyles';
import { generateCopy } from '../../copy';

export type LandingProps = OfferDetails & { joinUrl: string; country: Country };

export const LandingPage = ({
  referralOfferDTO,
  referrerUsername,
  referrerFirstName,
  joinUrl,
  country,
}: LandingProps) => {
  const theme = useTheme();

  const copy = generateCopy(
    referralOfferDTO,
    referrerUsername,
    referrerFirstName,
    country === 'canada'
  );

  const {
    cppPromotionInfo,
    offerType,
    originatingProduct,
    referralOfferDataList,
    tncUrl,
  } = referralOfferDTO;

  const casCppOfferData = getCppOfferData(
    cppPromotionInfo,
    'CASINO'
  ) as CppPromotionInfo;

  const casinoRefereeRewardAmount = casCppOfferData?.refereeRewardAmount;
  const productSpecificReferralOffer = referralOfferDataList?.find(
    (offer) => offer.product === originatingProduct
  );
  const rewardType =
    productSpecificReferralOffer?.referralOfferRewardType as RewardType;

  const headerImage =
    rewardType === 'BONUS_SPINS_REWARD'
      ? theme.icons.landingBonusSpins
      : theme.icons.landingGraphic;

  const isBonusSpinsZeroRecruit =
    rewardType === 'BONUS_SPINS_REWARD' &&
    casCppOfferData?.refereeRewardAmount === 0;

  return (
    <CopyContext.Provider value={copy}>
      <LandingStyle>
        <Header />
        <TopMargin value={theme.margins.landingPage} />
        <Container data-testid="main-container">
          <Graphic>
            <ReactSVG src={theme.icons.leftDots} />
            <SvgWrapper>
              <ReactSVG src={headerImage} />
            </SvgWrapper>
            <ReactSVG src={theme.icons.rightDots} />
          </Graphic>
          <TopMargin value={16}>
            <LandingWelcome isBonusSpinsZeroRecruit={isBonusSpinsZeroRecruit} />
          </TopMargin>
          <LandingHowItWorks
            casinoRefereeRewardAmount={casinoRefereeRewardAmount}
            offerType={offerType}
            rewardType={rewardType}
            joinUrl={joinUrl}
            tncUrl={tncUrl}
            country={country}
          />
          <Footer product={originatingProduct} country={country} />
        </Container>
      </LandingStyle>
    </CopyContext.Provider>
  );
};
