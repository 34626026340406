import { BrowserView, MobileView } from 'react-device-detect';
import { NativeInvite } from './NativeInvite';
import { WebInvite } from './WebInvite';

export type InviteProps = {
  casinoRefereeRewardAmount: number;
  link: string;
  percentage: number;
  previewText: string;
  sbkRefereeRewardAmount: number;
};

export const Invite = ({
  casinoRefereeRewardAmount,
  link,
  percentage,
  previewText,
  sbkRefereeRewardAmount,
}: InviteProps) => {
  const generateTextToCopy = () => {
    return previewText
      .replace('%sbkRefereeRewardAmount%', sbkRefereeRewardAmount?.toString())
      .replace(
        '%casinoRefereeRewardAmount%',
        casinoRefereeRewardAmount?.toString()
      )
      .replace('%percentage%', percentage?.toString());
  };

  return (
    <>
      <BrowserView>
        <WebInvite previewText={generateTextToCopy()} link={link} />
      </BrowserView>
      <MobileView>
        <NativeInvite previewText={generateTextToCopy()} link={link} />
      </MobileView>
    </>
  );
};
