import { ReactSVG } from 'react-svg';
import useMeasure from 'react-use-measure';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { ResizeObserver } from '@juggle/resize-observer';

import {
  determineAsymmetric,
  getCppOfferData,
  getReferralOfferData,
} from '../../../app/utils/commonFunctions';
import {
  HeaderText,
  HowItWorks,
  HeaderTextSubtitle,
  Invite,
  MaxReferrals,
  TermsAndConditions,
  WelcomeText,
  YourReferrals,
} from '../../components';
import {
  Column,
  Container,
  Home,
  HorizontalPadding,
  ImageDiv,
  Row,
  TopMargin,
  TopMarginText,
} from './ReferrerHomeStyle';
import {
  Offer,
  CppPromotionInfo,
  ReferralOfferDataList,
} from '../../types/OfferModel';
import { generateCopy } from '../../copy/generateCopy';
import { ReferredUser } from '../../types/ReferralModel';
import { CopyContext } from '../../contexts/CopyContext';
import { postHeight } from '../../utils/heightPostMessage';

type HomeProps = {
  referredUserData: ReferredUser[];
  offerData: Offer;
  link: string;
  isMaxReferralsReached: boolean;
};

export const ReferrerHomePage = (props: HomeProps) => {
  const theme = useTheme();
  const {
    cppPromotionInfo,
    referralOfferDataList,
    offerType,
    tncUrl,
    previewText,
    offerWindowDays,
    originatingProduct,
  } = props.offerData;

  const [componentHeight, setComponentHeight] = useState(0);
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

  const copy = generateCopy(props.offerData);

  useEffect(() => {
    setComponentHeight(height);
  }, [height]);

  useEffect(() => {
    postHeight(height);
  }, [componentHeight]);

  const sbkCppOfferData = getCppOfferData(
    cppPromotionInfo,
    'SB'
  ) as CppPromotionInfo;
  const casCppOfferData = getCppOfferData(
    cppPromotionInfo,
    'CASINO'
  ) as CppPromotionInfo;

  const sbkReferralOfferData = getReferralOfferData(
    referralOfferDataList,
    'SB'
  ) as ReferralOfferDataList;

  const casReferralOfferData = getReferralOfferData(
    referralOfferDataList,
    'CASINO'
  ) as ReferralOfferDataList;

  const isAsymmetric = determineAsymmetric(
    sbkReferralOfferData?.referrerPromoRewardAmount,
    sbkCppOfferData?.refereeRewardAmount,
    casReferralOfferData?.referrerPromoRewardAmount,
    casCppOfferData?.refereeRewardAmount,
    offerType
  );

  const productSpecificReferralOffer = referralOfferDataList?.find(
    (offer) => offer.product === originatingProduct
  ) as ReferralOfferDataList;

  const productSpecificPromotionInfo = cppPromotionInfo?.find(
    (offer) => offer.product === originatingProduct
  ) as CppPromotionInfo;

  const { referralOfferRewardType = 'EXTRA_WALLET_REWARD' } =
    productSpecificReferralOffer;
  const { percentage = 0 } = productSpecificPromotionInfo;

  const headerImage =
    theme.icons[
      referralOfferRewardType === 'BONUS_SPINS_REWARD' ? 'bonusSpins' : 'main'
    ];

  const bonusSpinsStyle =
    referralOfferRewardType === 'BONUS_SPINS_REWARD' ? 'bonusSpins' : undefined;

  const isBonusSpinsZeroRecruit =
    referralOfferRewardType === 'BONUS_SPINS_REWARD' &&
    casCppOfferData?.refereeRewardAmount === 0;

  return (
    <CopyContext.Provider value={copy}>
      <Home ref={ref}>
        <Container data-testid="main-container">
          <HeaderText text="Refer a friend" />
          <HorizontalPadding>
            <Row data-testid="welcome-panel">
              <Column>
                <TopMarginText
                  value={theme.margins.topSection.topMarginSpacing.extraLarge}
                >
                  <WelcomeText
                    isBonusSpinsZeroRecruit={isBonusSpinsZeroRecruit}
                  />
                </TopMarginText>
                <TopMargin
                  value={theme.margins.topSection.topMarginSpacing.extraSmall}
                >
                  <HeaderTextSubtitle
                    offerType={offerType}
                    isAsymmetric={isAsymmetric}
                  />
                </TopMargin>
                <TopMargin
                  value={theme.margins.topSection.topMarginSpacing.small}
                >
                  <TermsAndConditions tncUrl={tncUrl} />
                </TopMargin>
              </Column>

              <ImageDiv className={bonusSpinsStyle}>
                <ReactSVG src={headerImage} />
              </ImageDiv>
            </Row>
            <TopMargin value={theme.margins.topSection.topMarginSpacing.medium}>
              <MaxReferrals
                isMaxReferralsReached={props.isMaxReferralsReached}
                offerType={offerType}
              />
            </TopMargin>
            <TopMargin value={theme.margins.topSection.topMarginSpacing.large}>
              <Invite
                sbkRefereeRewardAmount={sbkCppOfferData?.refereeRewardAmount}
                casinoRefereeRewardAmount={casCppOfferData?.refereeRewardAmount}
                percentage={percentage}
                previewText={previewText}
                link={props.link}
              />
            </TopMargin>
          </HorizontalPadding>
          <YourReferrals referredUserList={props.referredUserData} />
          <TopMargin value={theme.margins.topSection.topMarginSpacing.small}>
            <HowItWorks
              sbkReferrerRewardAmount={
                sbkReferralOfferData?.referrerPromoRewardAmount
              }
              sbkRefereeRewardAmount={sbkCppOfferData?.refereeRewardAmount}
              sbkWagerAmount={sbkCppOfferData?.wagerAmount}
              casinoReferrerRewardAmount={
                casReferralOfferData?.referrerPromoRewardAmount
              }
              casinoRefereeRewardAmount={casCppOfferData?.refereeRewardAmount}
              casinoWagerAmount={casCppOfferData?.wagerAmount}
              offerType={offerType}
              tncUrl={tncUrl}
              offerWindowDays={offerWindowDays}
              rewardType={referralOfferRewardType}
              percentage={percentage}
            />
          </TopMargin>
        </Container>
      </Home>
    </CopyContext.Provider>
  );
};
